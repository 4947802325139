<template>
  <div class="content">
    <img :src="topImage" alt="">
    <img :src="contentImage" alt="">
  </div>
</template>

<script>
export default {
  name: 'FilmDomesticDetail',
  data() {
    return {
      topImage: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-11-25/20/yuelvhuinMK4QrTbHG1606307436.png',
      contentImage: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-11-25/20/yuelvhui7rhFEuAl3O1606307504.png'
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {}
}
</script>

<style lang="less" scoped>
html, body, #app, .content {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.content {
  img {
    width: 100%;
  }
}
</style>
